import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faSave,
    faArrowLeft,
    faDownload,
    faCalendar,
    faMapMarker,
    faEnvelope,
    faPhone, faAddressBook, faAddressCard, faMap, faMapLocation, faMapLocationDot, faTrash, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import './ResumeEditor.css';
import logo from '../logo.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const generatePDF = async () => {
    const resumePreview = document.querySelector('.resume-preview'); // Select the content to capture
    if (!resumePreview) {
        alert('Content not found!');
        return;
    }

    try {
        const canvas = await html2canvas(resumePreview, { scale: 2, useCORS: true }); // Capture the content with higher resolution
        const imgData = canvas.toDataURL('image/png'); // Convert canvas to image data
        const pdf = new jsPDF('p', 'mm', 'a4'); // Create a PDF instance in A4 size

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Resize the canvas to fit into A4
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        const finalWidth = imgWidth * ratio;
        const finalHeight = imgHeight * ratio;

        const x = (pdfWidth - finalWidth) / 2; // Center the image horizontally
        const y = 10; // Top margin for the content

        pdf.addImage(imgData, 'PNG', x, y, finalWidth, finalHeight); // Add image to the PDF
        pdf.save('Resume.pdf'); // Download the PDF
    } catch (error) {
        console.error('Error generating PDF:', error);
        alert('Failed to generate PDF.');
    }
};

const ResumeEditor = () => {
    const [resumeData, setResumeData] = useState({
        personalData: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            postalCode: '',
            city: '',
            slogan: '',
            birthdate: '',
            birthplace: '',
            driverslicense: '',
            nationality: '',
            maritalstatus: '',
            customFields: [],
            languageFields: [],
            educationFields: [],
            referencesFields: [],
            certificatesFields: [],
        },
        photo: null,
        template: 'template1'
    });
    const {id} = useParams();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [previewPhoto, setPreviewPhoto] = useState(null); // Separate state for the preview image

    useEffect(() => {
        const fetchCV = async () => {
            if (id) {
                try {
                    const {data} = await axios.get(`https://backend.cvblok.com/cvs/${id}`, {
                        headers: {Authorization: `Bearer ${token}`},
                    });

                    // Check if data and personalData exist
                    if (data && data.personalData) {
                        setResumeData({
                            personalData: data.personalData,
                            photo: data.personalData.photo || null,
                            template: data.personalData.template || 'template1', // If template exists in response, set it, else default to 'template1'
                        });
                    }
                } catch (error) {
                    console.error('Error fetching CV:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                // If no CV ID is provided, set default data
                setResumeData({
                    personalData: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        address: '',
                        postalCode: '',
                        city: '',
                        slogan: '',
                        birthdate: '',
                        birthplace: '',
                        driverslicense: '',
                        nationality: '',
                        maritalstatus: '',
                        customFields: [],
                        languageFields: [],
                        educationFields: [],
                        referencesFields: [],
                        certificatesFields: [],
                    },
                    photo: null,
                    template: 'template1',
                });
                setLoading(false);
            }
        };
        fetchCV();
    }, [id, token]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, [name]: value},
            photo: prevData.photo
        }));
        setUnsavedChanges(true);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            const photoUrl = URL.createObjectURL(file); // Generate Blob URL for preview
            setPreviewPhoto(photoUrl); // Update the preview state
            setResumeData((prevData) => ({
                ...prevData,
                photo: file, // Keep the file object for uploading
            }));
            setUnsavedChanges(true); // Mark changes as unsaved
        }
    };

    const handleCustomFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.customFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, customFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const addCustomField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                customFields: [...prevData.personalData.customFields, {title: '', value: ''}]
            }
        }));
        setUnsavedChanges(true);
    };

    const removeCustomField = (index) => {
        const updatedFields = resumeData.personalData.customFields.filter((_, i) => i !== index);
        setResumeData({...resumeData, personalData: {customFields: updatedFields}});
        setUnsavedChanges(true);
    };

    const handleLanguageChange = (index, field, value) => {
        const updatedLanguages = [...resumeData.personalData.languageFields];
        updatedLanguages[index] = {...updatedLanguages[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, languageFields: updatedLanguages}
        }));
        setUnsavedChanges(true);
    };

    const addLanguageField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                languageFields: [
                    ...prevData.personalData.languageFields,
                    {language: '', level: 1}
                ]
            }
        }));
        setUnsavedChanges(true);
    };

    const removeLanguageField = (index) => {
        const updatedLanguages = resumeData.personalData.languageFields.filter(
            (_, i) => i !== index
        );
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, languageFields: updatedLanguages}
        }));
        setUnsavedChanges(true);
    };

    const handleEducationFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.educationFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, educationFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const addEducationField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                educationFields: [
                    ...prevData.personalData.educationFields,
                    {
                        school: '',
                        location: '',
                        startMonth: '',
                        startYear: '',
                        endMonth: '',
                        endYear: '',
                        isCurrent: false,
                        description: '',
                    },
                ],
            },
        }));
        setUnsavedChanges(true);
    };

    const removeEducationField = (index) => {
        const updatedFields = resumeData.personalData.educationFields.filter(
            (_, i) => i !== index
        );
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, educationFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const handleReferenceFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.referencesFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, referencesFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const addReferenceField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                referencesFields: [
                    ...prevData.personalData.referencesFields,
                    {
                        name: '',
                        organization: '',
                        location: '',
                        phone: '',
                        email: '',
                    },
                ],
            },
        }));
        setUnsavedChanges(true);
    };

    const removeReferenceField = (index) => {
        const updatedFields = resumeData.personalData.referencesFields.filter(
            (_, i) => i !== index
        );
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, referencesFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const handleCertificateFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.certificatesFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, certificatesFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const addCertificateField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                certificatesFields: [
                    ...prevData.personalData.certificatesFields,
                    {
                        certificate: '',
                        startMonth: '',
                        startYear: '',
                        endMonth: '',
                        endYear: '',
                        ongoing: false,
                        description: '',
                    },
                ],
            },
        }));
        setUnsavedChanges(true);
    };

    const removeCertificateField = (index) => {
        const updatedFields = resumeData.personalData.certificatesFields.filter(
            (_, i) => i !== index
        );
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, certificatesFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token'); // Get token from localStorage
        if (!token) {
            alert("You must be logged in to create or update a CV");
            return;
        }

        const formData = new FormData();
        formData.append('personal_data', JSON.stringify({
            firstName: resumeData.personalData.firstName,
            lastName: resumeData.personalData.lastName,
            email: resumeData.personalData.email,
            phone: resumeData.personalData.phone,
            address: resumeData.personalData.address,
            postalCode: resumeData.personalData.postalCode,
            city: resumeData.personalData.city,
            slogan: resumeData.personalData.slogan,
            birthdate: resumeData.personalData.birthdate,
            birthplace: resumeData.personalData.birthplace,
            driverslicense: resumeData.personalData.driverslicense,
            nationality: resumeData.personalData.nationality,
            maritalstatus: resumeData.personalData.maritalstatus,
            customFields: resumeData.personalData.customFields,
            languageFields: resumeData.personalData.languageFields,
            educationFields: resumeData.personalData.educationFields,
            referencesFields: resumeData.personalData.referencesFields,
            certificatesFields: resumeData.personalData.certificatesFields,
        }));

        if (resumeData.photo) {
            formData.append('photo', resumeData.photo);
        }

        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
        // Creating new CV
        formData.append('user_id', userId);

        try {
            if (id) {
                formData.append('cv_id', id);
                const response = await axios.post(`https://backend.cvblok.com/cvs/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setUnsavedChanges(false);
                alert('CV updated successfully');
                console.log('CV updated successfully:', response.data);
            } else {
                const response = await axios.post('https://backend.cvblok.com/cvs/create', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log('CV created successfully:', response.data);
                navigate(`/editor/${response.data.id}`);
            }
        } catch (error) {
            console.error('Error saving CV:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(error.response?.data?.message || 'An error occurred');
            }
        }
    };

    const handleTemplateChange = (e) => {
        setResumeData((prevData) => ({
            ...prevData,
            template: e.target.value
        }));
    };

    const handleBackToDashboard = () => {
        if (unsavedChanges) {
            const confirmLeave = window.confirm('You have unsaved changes. Do you want to leave without saving?');
            if (!confirmLeave) return;
        }
        navigate('/dashboard');
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="top-bar">
                <h1>CVBlok Editor</h1>
                <div>
                    <button className="top-bar-button" onClick={generatePDF}>
                        <FontAwesomeIcon icon={faDownload} style={{marginRight: 5}}/>
                        Download CV
                    </button>

                    <button onClick={handleBackToDashboard} className="top-bar-button"><FontAwesomeIcon
                        icon={faArrowLeft} style={{marginRight: 5}}/>Zurück zum Dashboard
                    </button>
                    <button onClick={handleSubmit} className="top-bar-button"><FontAwesomeIcon icon={faSave}
                                                                                               style={{marginRight: 5}}/>Speichern
                    </button>
                </div>
            </div>
            <div className="editor-layout">
                <div className="editor-form">
                    <h2>{id ? 'Lebenslauf bearbeiten' : 'Neuen Lebenslauf erstellen'}</h2>

                    <h3>Persönliches</h3>
                    <div>
                        <label>Foto hochladen:</label>
                        <input type="file" onChange={handleFileChange}/>
                    </div>

                    {/* Standard Fields */}
                    <input type="text" name="firstName" value={resumeData.personalData.firstName}
                           onChange={handleChange} placeholder="Vorname"/>
                    <input type="text" name="lastName" value={resumeData.personalData.lastName} onChange={handleChange}
                           placeholder="Nachname"/>
                    <input type="text" name="slogan" value={resumeData.personalData.slogan} onChange={handleChange}
                           placeholder="Slogan"/>
                    <input type="email" name="email" value={resumeData.personalData.email} onChange={handleChange}
                           placeholder="E-Mail-Adresse"/>
                    <input type="tel" name="phone" value={resumeData.personalData.phone} onChange={handleChange}
                           placeholder="Telefonnummer"/>
                    <input type="text" name="address" value={resumeData.personalData.address} onChange={handleChange}
                           placeholder="Adresse"/>
                    <input type="text" name="postalCode" value={resumeData.personalData.postalCode}
                           onChange={handleChange} placeholder="Postleitzahl"/>
                    <input type="text" name="city" value={resumeData.personalData.city} onChange={handleChange}
                           placeholder="Ort"/>
                    <div>
                        <input
                            type="date"
                            name="birthdate"
                            value={resumeData.personalData.birthdate}
                            onChange={handleChange}
                            placeholder="Geburtstag"
                        />
                        <input
                            type="text"
                            name="birthplace"
                            value={resumeData.personalData.birthplace}
                            onChange={handleChange}
                            placeholder="Geburtsort"
                        />

                        {/* Dropdown for Marital Status */}
                        <label htmlFor="maritalstatus">Familienstand</label>
                        <select
                            id="maritalstatus"
                            name="maritalstatus"
                            value={resumeData.personalData.maritalstatus}
                            onChange={handleChange}
                        >
                            <option value="">Bitte wählen</option>
                            <option value="ledig">Ledig</option>
                            <option value="verheiratet">Verheiratet</option>
                            <option value="geschieden">Geschieden</option>
                            <option value="verwitwet">Verwitwet</option>
                            <option value="eingetragene Partnerschaft">Eingetragene Partnerschaft</option>
                        </select>

                        {/* Checkbox for Driver's License */}
                        <div>
                            <label htmlFor="driverslicense">Führerschein</label>
                            <input
                                type="checkbox"
                                id="driverslicense"
                                name="driverslicense"
                                checked={resumeData.personalData.driverslicense === 'Ja'}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: 'driverslicense',
                                            value: e.target.checked ? 'Ja' : 'Nein',
                                        },
                                    })
                                }
                            />{' '}
                            Ja
                        </div>

                        <input
                            type="text"
                            name="nationality"
                            value={resumeData.personalData.nationality}
                            onChange={handleChange}
                            placeholder="Staatsangehörigkeit"
                        />
                    </div>

                    <div className="language-fields">
                        <h3>Sprachen</h3>
                        {resumeData.personalData.languageFields.map((field, index) => (
                            <div key={index} className="language-field">
                                <input
                                    type="text"
                                    placeholder="Sprache"
                                    value={field.language}
                                    onChange={(e) =>
                                        handleLanguageChange(index, 'language', e.target.value)
                                    }
                                />
                                <input
                                    type="range"
                                    min="1"
                                    max="5"
                                    value={field.level}
                                    onChange={(e) =>
                                        handleLanguageChange(index, 'level', parseInt(e.target.value, 10))
                                    }
                                />
                                <span>
                {['Anfänger', 'Grundkenntnisse', 'Fortgeschritten', 'Fließend', 'Muttersprache'][
                field.level - 1
                    ]}
            </span>
                                <button onClick={() => removeLanguageField(index)}><FontAwesomeIcon icon={faTrashAlt}
                                                                                                    className="icon-sidebar icon-white"/>
                                </button>
                            </div>
                        ))}
                        <button onClick={addLanguageField}>+ Sprache hinzufügen</button>
                    </div>

                    <h3>Ausbildung</h3>
                    <div className="education-fields">
                        {resumeData.personalData.educationFields.map((field, index) => (
                            <div key={index} className="education-field">
                                <input
                                    type="text"
                                    placeholder="Schule"
                                    value={field.school}
                                    onChange={(e) =>
                                        handleEducationFieldChange(index, 'school', e.target.value)
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="Ort"
                                    value={field.location}
                                    onChange={(e) =>
                                        handleEducationFieldChange(index, 'location', e.target.value)
                                    }
                                />
                                <div className="date-group">
                                    <label>Startdatum:</label>
                                    <select
                                        value={field.startMonth}
                                        onChange={(e) =>
                                            handleEducationFieldChange(index, 'startMonth', e.target.value)
                                        }
                                    >
                                        <option value="">Monat</option>
                                        {Array.from({length: 12}, (_, i) => (
                                            <option key={i} value={i + 1}>
                                                {new Date(0, i).toLocaleString('de-DE', {month: 'long'})}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="number"
                                        placeholder="Jahr"
                                        value={field.startYear}
                                        onChange={(e) =>
                                            handleEducationFieldChange(index, 'startYear', e.target.value)
                                        }
                                    />
                                </div>
                                <div className="date-group">
                                    <label>Enddatum:</label>
                                    <select
                                        value={field.endMonth}
                                        onChange={(e) =>
                                            handleEducationFieldChange(index, 'endMonth', e.target.value)
                                        }
                                        disabled={field.isCurrent}
                                    >
                                        <option value="">Monat</option>
                                        {Array.from({length: 12}, (_, i) => (
                                            <option key={i} value={i + 1}>
                                                {new Date(0, i).toLocaleString('de-DE', {month: 'long'})}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="number"
                                        placeholder="Jahr"
                                        value={field.endYear}
                                        onChange={(e) =>
                                            handleEducationFieldChange(index, 'endYear', e.target.value)
                                        }
                                        disabled={field.isCurrent}
                                    />
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={field.isCurrent}
                                            onChange={(e) =>
                                                handleEducationFieldChange(
                                                    index,
                                                    'isCurrent',
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        Laufend
                                    </label>
                                </div>
                                <textarea
                                    placeholder="Beschreibung"
                                    value={field.description}
                                    onChange={(e) =>
                                        handleEducationFieldChange(index, 'description', e.target.value)
                                    }
                                />
                                <button onClick={() => removeEducationField(index)}><FontAwesomeIcon icon={faTrashAlt}
                                                                                                     className="icon-sidebar icon-white"/>
                                </button>
                            </div>
                        ))}
                        <button onClick={addEducationField}>+ Ausbildung Hinzufügen</button>
                    </div>

                    <h3>Referenzen</h3>
                    <div className="references-fields">
                        {resumeData.personalData.referencesFields.map((field, index) => (
                            <div key={index} className="reference-field">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    value={field.name}
                                    onChange={(e) =>
                                        handleReferenceFieldChange(index, 'name', e.target.value)
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="Organisation"
                                    value={field.organization}
                                    onChange={(e) =>
                                        handleReferenceFieldChange(index, 'organization', e.target.value)
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="Ort"
                                    value={field.location}
                                    onChange={(e) =>
                                        handleReferenceFieldChange(index, 'location', e.target.value)
                                    }
                                />
                                <input
                                    type="tel"
                                    placeholder="Telefonnummer"
                                    value={field.phone}
                                    onChange={(e) =>
                                        handleReferenceFieldChange(index, 'phone', e.target.value)
                                    }
                                />
                                <input
                                    type="email"
                                    placeholder="E-Mail-Adresse"
                                    value={field.email}
                                    onChange={(e) =>
                                        handleReferenceFieldChange(index, 'email', e.target.value)
                                    }
                                />
                                <div className="reference-buttons">
                                    <button onClick={() => removeReferenceField(index)}><FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className="icon-sidebar icon-white"/></button>
                                </div>
                            </div>
                        ))}
                        <button onClick={addReferenceField}>+ Referenz hinzufügen</button>
                    </div>

                    <h3>Zertifikate</h3>
                    <div className="certificates-fields">
                        {resumeData.personalData.certificatesFields.map((field, index) => (
                            <div key={index} className="certificate-field">
                                <input
                                    type="text"
                                    placeholder="Zertifikat"
                                    value={field.certificate}
                                    onChange={(e) =>
                                        handleCertificateFieldChange(index, 'certificate', e.target.value)
                                    }
                                />
                                <div className="date-fields">
                                    <select
                                        value={field.startMonth}
                                        onChange={(e) =>
                                            handleCertificateFieldChange(index, 'startMonth', e.target.value)
                                        }
                                    >
                                        <option value="">Monat</option>
                                        {['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'].map(
                                            (month, idx) => (
                                                <option key={idx} value={month}>
                                                    {month}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <select
                                        value={field.startYear}
                                        onChange={(e) =>
                                            handleCertificateFieldChange(index, 'startYear', e.target.value)
                                        }
                                    >
                                        <option value="">Jahr</option>
                                        {[...Array(30).keys()].map((offset) => (
                                            <option key={offset} value={2023 - offset}>
                                                {2023 - offset}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        value={field.endMonth}
                                        disabled={field.ongoing}
                                        onChange={(e) =>
                                            handleCertificateFieldChange(index, 'endMonth', e.target.value)
                                        }
                                    >
                                        <option value="">Monat</option>
                                        {['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'].map(
                                            (month, idx) => (
                                                <option key={idx} value={month}>
                                                    {month}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <select
                                        value={field.endYear}
                                        disabled={field.ongoing}
                                        onChange={(e) =>
                                            handleCertificateFieldChange(index, 'endYear', e.target.value)
                                        }
                                    >
                                        <option value="">Jahr</option>
                                        {[...Array(30).keys()].map((offset) => (
                                            <option key={offset} value={2023 - offset}>
                                                {2023 - offset}
                                            </option>
                                        ))}
                                    </select>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={field.ongoing}
                                            onChange={(e) =>
                                                handleCertificateFieldChange(index, 'ongoing', e.target.checked)
                                            }
                                        />{' '}
                                        laufend
                                    </label>
                                </div>
                                <textarea
                                    placeholder="Beschreibung"
                                    value={field.description}
                                    onChange={(e) =>
                                        handleCertificateFieldChange(index, 'description', e.target.value)
                                    }
                                />
                                <div className="certificate-buttons">
                                    <button onClick={() => removeCertificateField(index)}><FontAwesomeIcon
                                        icon={faTrashAlt}
                                        className="icon-sidebar icon-white"/></button>
                                </div>
                            </div>
                        ))}
                        <button onClick={addCertificateField}>+ Zertifikat hinzufügen</button>
                    </div>

                    <h3>Praktika</h3>
                    <h3>Nebentätigkeiten</h3>
                    <h3>Weiterbildungskurse</h3>
                    <h3>Berufserfahrung</h3>
                    <h3>Kenntnisse und Fähigkeiten</h3>

                    {/* Custom Fields */}
                    <div className="custom-fields">
                        <h3>Sonstiges</h3>
                        {resumeData.personalData.customFields.map((field, index) => (
                            <div key={index} className="custom-field">
                                <input type="text" placeholder="Titel" value={field.title}
                                       onChange={(e) => handleCustomFieldChange(index, 'title', e.target.value)}/>
                                <input type="text" placeholder="Wert" value={field.value}
                                       onChange={(e) => handleCustomFieldChange(index, 'value', e.target.value)}/>
                                <button onClick={() => removeCustomField(index)}><FontAwesomeIcon icon={faTrashAlt}
                                                                                                  className="icon-sidebar icon-white"/>
                                </button>
                            </div>
                        ))}
                        <button onClick={addCustomField}>+ Sonstiges Feld</button>
                    </div>
                </div>

                <div className="resume-preview">
                    <div className="preview-sidebar">
                        <div className="personal-data">
                            <img
                                src={
                                    previewPhoto || (resumeData.photo && typeof resumeData.photo === 'string' ? `https://backend.cvblok.com/${resumeData.photo}` : logo)
                                }
                                alt="Profile"
                                className="preview-photo"
                            />

                            <div className="headSidebar">
                                <h2>{resumeData.personalData.firstName} {resumeData.personalData.lastName}</h2>
                                <p>{resumeData.personalData.slogan || 'Ihre Position/Headline'}</p>
                            </div>

                            <hr/>
                            <p>
                                <FontAwesomeIcon icon={faCalendar} className="icon-sidebar"/>
                                {resumeData.personalData.birthdate ? new Intl.DateTimeFormat('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                }).format(new Date(resumeData.personalData.birthdate)) : 'Nicht angegeben'}</p>
                            <p><FontAwesomeIcon icon={faMapMarker}
                                                className="icon-sidebar"/> {resumeData.personalData.birthplace || 'Nicht angegeben'}
                            </p>
                            <p><FontAwesomeIcon icon={faEnvelope}
                                                className="icon-sidebar"/> {resumeData.personalData.email || 'Nicht angegeben'}
                            </p>
                            <p><FontAwesomeIcon icon={faPhone}
                                                className="icon-sidebar"/> {resumeData.personalData.phone || 'Nicht angegeben'}
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faMapLocationDot}
                                                 className="icon-sidebar"/> {resumeData.personalData.address}, {resumeData.personalData.postalCode} {resumeData.personalData.city}
                            </p>
                            <hr/>
                            <p>
                                <strong>Staatsangehörigkeit:</strong> {resumeData.personalData.nationality || 'Nicht angegeben'}
                            </p>
                            <p>
                                <strong>Familienstand:</strong> {resumeData.personalData.maritalstatus || 'Nicht angegeben'}
                            </p>
                            <p>
                                <strong>Führerschein:</strong> {resumeData.personalData.driverslicense === 'Ja' ? 'Ja' : 'Nein'}
                            </p>
                            <hr/>
                            <h3>Sprachen</h3>
                            <ul style={{textAlign: 'left'}}>
                                {resumeData.personalData.languageFields.map((field, index) => (
                                    <li key={index} style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                        <span style={{flex: 1, fontSize: '.8em'}}>{field.language}</span>
                                        <div style={{display: 'flex', gap: '5px'}}>
                                            {Array.from({length: 5}, (_, i) => (
                                                <span
                                                    key={i}
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '10px',
                                                        height: '10px',
                                                        borderRadius: '50%',
                                                        backgroundColor: i < field.level ? '#005cbf' : '#ddd'
                                                    }}
                                                ></span>
                                            ))}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="preview-content">
                        {resumeData.personalData.educationFields.length > 0 && (
                            <div>
                                <h3>Ausbildung</h3>
                                <ul>
                                    {resumeData.personalData.educationFields.map((field, index) => (
                                        <li key={index} style={{marginBottom: '10px'}}>
                                            <strong>{field.school}</strong>, {field.location}
                                            <br/>
                                            {field.startMonth && field.startYear
                                                ? `${new Date(0, field.startMonth - 1).toLocaleString('de-DE', {
                                                    month: 'long',
                                                })} ${field.startYear}`
                                                : ''}
                                            {field.isCurrent
                                                ? ' - Laufend'
                                                : field.endMonth && field.endYear
                                                    ? ` - ${new Date(0, field.endMonth - 1).toLocaleString('de-DE', {
                                                        month: 'long',
                                                    })} ${field.endYear}`
                                                    : ''}
                                            <br/>
                                            <em>{field.description}</em>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {resumeData.personalData.referencesFields.length > 0 && (
                            <div>
                                <h3>Referenzen</h3>
                                <ul>
                                    {resumeData.personalData.referencesFields.map((field, index) => (
                                        <li key={index} style={{marginBottom: '10px'}}>
                                            <strong>{field.name}</strong> - {field.organization}, {field.location}
                                            <br/>
                                            <span>Telefon: {field.phone}</span> | <span>E-Mail: {field.email}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {resumeData.personalData.certificatesFields.length > 0 && (
                            <div>
                                <h3>Zertifikate</h3>
                                <ul>
                                    {resumeData.personalData.certificatesFields.map((field, index) => (
                                        <li key={index} style={{marginBottom: '10px'}}>
                                            <strong>{field.certificate}</strong> <br/>
                                            Zeitraum:{' '}
                                            {field.ongoing
                                                ? `${field.startMonth} ${field.startYear} - laufend`
                                                : `${field.startMonth} ${field.startYear} - ${field.endMonth} ${field.endYear}`}
                                            <br/>
                                            <p>{field.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <h3>Praktika</h3>
                        <h3>Nebentätigkeiten</h3>
                        <h3>Weiterbildungskurse</h3>
                        <h3>Berufserfahrung</h3>
                        <h3>Kenntnisse und Fähigkeiten</h3>

                        {/* Custom Fields Preview */}
                        {resumeData.personalData.customFields.length > 0 && (
                            <div className="padding-bottom-20">
                                <h3>Sonstiges</h3>
                                {resumeData.personalData.customFields.map((field, index) => (
                                    <div key={index} className="custom-field-preview">
                                        <strong>{field.title}: </strong>{field.value}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
        ;
};

export default ResumeEditor;
